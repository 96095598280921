<!--
 * @Description: 
 * @Author: 爱酱丶
 * @Date: 2022-11-08 10:21:26
 * @LastEditTime: 2022-12-09 16:18:32
 * @LastEditors: 爱酱丶
-->
<template>
  <div class="login-page">
    <div class="box">
      <div class="text">
        {{ $t('pclogin').l1 }}
        <router-link to="register" class="text-register">{{
          $t('pclogin').l2
        }}</router-link>
      </div>
      <div class="login-box">
        <div class="title">{{ $t('pclogin').l3 }}</div>
        <!-- switchTab -->

        <!-- 表单 -->
        <el-form
          ref="form"
          :model="loginForm"
          :rules="loginFormRules"
          :show-message="false"
        >
          <el-form-item prop="userName">
            <el-input
              v-model="loginForm.userName"
              :placeholder="$t('pclogin').l4"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="loginForm.password"
              :placeholder="$t('pclogin').l5"
            ></el-input>
          </el-form-item>
          <!-- 忘记密码 -->
          <!-- <div class="forget-password">
            <a href="/account/forget" class="personlink text-14"
              >{{ $t('pclogin').l6 }}?</a
            >
          </div> -->
          <el-button type="primary" @click="handleLogin">{{
            $t('pclogin').l7
          }}</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { Token } from '@/utils/mm'

import { EventBus } from '@/utils/event-bus'
export default {
  data() {
    return {
      loginForm: {
        userName: '',
        password: ''
      },
      loginFormRules: {
        userName: [{ required: true, trigger: 'blur' }],
        password: [{ required: true, trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 登录
    handleLogin() {
      this.$refs.form.validate(async (volid) => {
        if (volid) {
          // 说明校验通过
          try {
            // 获取refreshtoken
            let res = await userApi.getRefreshToken(this.loginForm)
            // 获取token
            let res2 = await userApi.login(res.Token)
            Token.set(res2.Token)
            this.loginSuccess()
          } catch (error) {
            // this.$toast(this.$t('login').fail)
          }
        } else {
          this.$toast(this.$t('login').fail)
        }
      })
    },
    loginSuccess() {
      this.$toast.success(this.$t('login').success)

      setTimeout(async () => {
        this.$router.push({ name: 'Home' })
        const res = await userApi.userInfo()
        EventBus.$emit('Login', res)
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.login-page {
  background-color: #f8f8f8;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    width: auto;

    min-height: 7.1rem;
    margin: 0.55rem auto;
    .text {
      color: #7a7a7a;
      margin-bottom: 0.1rem;
      text-align: right;
      font-size: 0.18rem;
      .text-register {
        font-weight: 700;
        color: #3d67fa;
        font-size: 18px;
      }
    }
    .login-box {
      width: 4rem;
      height: 4.25rem;
      padding: 0.3rem;
      background-color: #fff;
      border-radius: 0.04rem;
      .title {
        font-family: PingFang SC;
        margin-bottom: 0.4rem;
        color: #1e1e1e;
        font-size: 0.3rem;
        height: 0.22rem;
        line-height: 0.22rem;
        font-style: normal;
        font-weight: 600;
        width: 100%;
        text-align: left;
      }

      .el-form {
        .el-form-item {
          &:nth-child(2) {
            margin-bottom: 20px;
          }
        }
        .forget-password {
          margin-bottom: 30px;
          text-align: left;
          height: unset !important;
          .personlink {
            color: #2e96f6;
            font-size: 14px;
          }
        }
        .el-button {
          margin-top: 40px;
          // margin-bottom: 20px;
          height: 46px;
          width: 100%;
          background-color: #3d67fa;
          text-align: center;
          font-weight: bolder;
          color: #fff;
        }
      }
    }
  }
}
</style>
